<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="查看图片" left-arrow :fixed="true" :z-index="9999" @click-left="back"></van-nav-bar>
  </div>
</template>
  
<script>
import { ImagePreview } from "vant";
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      img: this.$route.query.img,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
      // parent.WeixinJSBridge.call("closeWindow");
    },
  },
  created() {
    var _this = this;
    var img = this.$route.query.img.split(',');
    var idx = this.$route.query.idx
    ImagePreview({
      images: img,
      asyncClose: true,
      // closeable: true,
      startPosition:idx,
      onClose() {
        close();
        // _this.$router.go(-1);
      },
    });
    // this.onLoad();
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
::v-deep .van-image-preview{
  height: calc(100% - 46px);
  margin-top: 46px;
  top: 46px;
}
</style>


  
  